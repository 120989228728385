<template>
  <page-template>
    <template #nav>
      <basic-nav>
        <template slot="nav-left">
          <div class="flex justify-start">
            <back-button @click="goBack"></back-button>
          </div>
        </template>
        <template #nav-center>
          <h1 class="text-3xl">Verifone Admin</h1>
        </template>
      </basic-nav>
    </template>
    <template #body>
      <div class="flex justify-center">
        <basic-card title="Register Verifone Device" class="w-11/12 md:w-1/2">
          <template #body>
            <form @submit.prevent="registerDevice" class="space-y-4 text-left">
              <text-input
                  v-model="entryCode"
                  name="entryCode"
                  label="Entry Code"
                  :errored="entryCodeErrorMessage.length > 0"
                  :error-message="entryCodeErrorMessage"
                  @blur="$v.entryCode.$touch"
              />
              <text-input
                  v-model="ipAddress"
                  name="ipAddress"
                  label="IP Address"
                  :errored="ipAddressErrorMessage.length > 0"
                  :error-message="ipAddressErrorMessage"
                  @blur="$v.ipAddress.$touch"
              />
              <div class="flex items-center">
                <basic-button type="submit" title="Register" class="py-2"/>
                <div v-if="registering" class="flex items-center">
                  <span class="mx-2">Registering, enter entry code on device</span>
                  <font-awesome-icon spin icon="spinner" color="orange" />
                </div>
              </div>
            </form>
          </template>
        </basic-card>
      </div>
    </template>
  </page-template>
</template>

<script>
import BasicCard from "@/components/Helpers/Cards/BasicCard";
import BasicNav from "@/components/Helpers/Navs/BasicNav";
import PageTemplate from "@/components/Helpers/Pages/PageTemplate";
import TextInput from "@/components/Helpers/Inputs/TextInput";
import BasicButton from "@/components/Helpers/Buttons/BasicButton";
import BackButton from "@/components/Helpers/Buttons/BackButton";
import {required, minLength, maxLength, numeric, ipAddress} from "vuelidate/lib/validators";
import ToastMixin from "../../mixin/ToastMixin";

export default {
  name: "VerifoneRegister",
  components: {BackButton, BasicButton, TextInput, BasicCard, BasicNav, PageTemplate },
  mixins: [ToastMixin],
  data() {
    return {
      entryCode: "",
      ipAddress: "",
      registering: false,
    };
  },
  validations: {
    entryCode: {
      required,
      numeric,
      minLength: minLength(4),
      maxLength: maxLength(4)
    },
    ipAddress: {
      required,
      ipAddress
    }
  },
  computed: {
    entryCodeErrorMessage() {
      if (!this.$v.entryCode.$error) {
        return "";
      }
      if (!this.$v.entryCode.required) {
        return "Entry code required";
      }

      if (!this.$v.entryCode.numeric) {
        return "Entry code must only contain numbers";
      }

      if (!this.$v.entryCode.minLength || !this.$v.entryCode.maxLength) {
        return "Entry code must be 4 numbers";
      }
      return "";
    },
    ipAddressErrorMessage() {
      if (!this.$v.ipAddress.$error) {
        return "";
      }
      if (!this.$v.ipAddress.required) {
        return "IP address required";
      }

      if (!this.$v.ipAddress.ipAddress) {
        return "Please enter a valid IP address";
      }
      return "";
    }
  },
  methods: {
    goBack() {
      this.$router.push({name: "VerifoneStatus"});
    },
    onInput() {
      this.$v.entryCode.$touch();
    }
  }
}
</script>

<style scoped>

</style>
