<template>
  <div class="flex flex-col text-left">
    <label>
      {{label}}
    </label>
    <input
        v-bind="$attrs"
        :value="value"
        :class="['border border-gray-light h-8 focus:border-orange outline-none px-2',
          {
            'border-red': errored
          }
        ]"
        :name="name"
        :type="type"
        v-on="eventListeners"
    />
    <div v-if="errored">
      <span class="text-red">
        {{errorMessage}}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "TextInput",
  props: {
    value: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: "text"
    },
    errored: {
      type: Boolean,
      required: false,
      default: false
    },
    errorMessage: {
      type: String,
      required: false,
      default: ""
    }
  },
  computed: {
    eventListeners() {
      return {
        ...this.$listeners,
        input: (event) => {
          this.$emit("input", event.target.value);
        }
      }
    }
  },
  methods: {
    onInput(event) {
      this.$emit("input", event.target.value);
    }
  }
}
</script>

<style scoped>

</style>